import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { DocWrapper } from '../components/layout';
import SEO from '../components/seo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Card = makeShortcode("Card");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DocWrapper;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO lang="en" title="Teamwork App: Our GDPR commitment" mdxType="SEO" />
    <h2>{`Talkbox Commitment to GDPR`}</h2>
    <p>{`We always concerns our customers’ data, and has committed our efforts to prepare for the General Data Protection Regulation (GDPR), which goes into effect on May 25, 2018.`}</p>
    <p>{`This article highlights the data protection measurements we have taken and the efforts we have made towards complying GDPR.`}</p>
    <h3>{`What Talkbox is doing`}</h3>
    <ul>
      <li parentName="ul">{`Revise all our data set to ensure it comply with GDPR policy.`}</li>
      <li parentName="ul">{`Revise our security infrastructure and development procedure to cope with GDPR.`}</li>
      <li parentName="ul">{`Update and revise our contractual Gdpr to disclose and provide necessary transparency to user about how we use and what we collect.`}</li>
      <li parentName="ul">{`Appointed Data Protection Officer to handle corresponding to data privacy and control. For more, please visit our Privacy Policy.`}</li>
      <li parentName="ul">{`Established data breaching procedure to provide standard and timely action towards any data breaching incident or risk towards loss of users’ personal data.`}</li>
    </ul>
    <h3>{`Your Rights`}</h3>
    <p>{`Under GDPR policy, you have the right to access, rectification, erasure, restrict processing of your personal data. If you have any questions regarding the above, please email `}<a parentName="p" {...{
        "href": "mailto:privacy@talkboxapp.com"
      }}>{`privacy@talkboxapp.com`}</a></p>
    <h3>{`Data Protection Officer`}</h3>
    <p>{`Talkbox has appointed dedicated person as Data Protection Officer(DPO), if you want to communicate with our DPO, please email `}<a parentName="p" {...{
        "href": "mailto:dpo@talkboxapp.com"
      }}>{`dpo@talkboxapp.com`}</a></p>
    <h3>{`Data Portability`}</h3>
    <p>{`Talkbox is working on the measurement needed to fulfil data portability mentioned by GDPR. We will update at this policy as soon as possible. Meanwhile, for data portability question, please email `}<a parentName="p" {...{
        "href": "mailto:privacy@talkboxapp.com"
      }}>{`privacy@talkboxapp.com`}</a></p>
    <h3>{`Stay Updated`}</h3>
    <p>{`Talkbox may update this policy from time to time and as products are updated and new product features offering are available. Talkbox will publish any changes on website and notify the changes when the Privacy Policy is updated. Talkbox will not share personally-identifiable information with third parties without your consent unless required by law.`}</p>
    <h4>{`Contacting Talkbox`}</h4>
    <Card mt="l" mdxType="Card">
  <h5>Talkbox Limited</h5>
  <p fontSize="s">
    20/F., Harbourside HQ
    <br />
    8 Lam Chak Street,
    <br />
    Kowloon Bay,
    <br />
    Hong Kong
    <br />
    <br />
    Email: privacy@talkboxapp.com
  </p>
    </Card>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      